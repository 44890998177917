<template>
  <div>
    <template>
      <h4>
        Writing:
        <span>
          <b style="color: #ff8920;">
            <span class="mr-2">{{ score }}</span>
            <!-- <span v-if="!examInfo.can_suggest">
              <el-button type="warning" @click="$emit('openWarningSpeaking')">
                <i class="fas fa-lock"></i>
              </el-button>
            </span> -->
          </b>
        </span>
      </h4>
      <div class="tip" :style="hideTitle ? 'display:none' : ''">
        <div class="text">
          <router-link :to="{ name: 'Gradingsamples' }" target="_blank">
            <i class="el-icon-star-on"></i>
            {{ $t("toefl.balance.exp") }}
          </router-link>
        </div>
      </div>
      <table
        :class="hideTitle ? 'table' : 'table table-bordered'"
        v-for="(item, index) in list"
        :key="item.exam_question_id"
      >
        <thead :style="hideTitle ? 'display:none' : ''">
          <tr>
            <th class="green-td view-question-tr">
              <el-tooltip
                v-if="item.taken_time && item.taken_time > 0"
                class="item"
                effect="dark"
                :content="showTime(item.taken_time)"
                placement="top"
              >
                <a
                  target="_blank"
                  :href="getResolve(item.toefl_question_id)"
                  class="view_question"
                >
                  <b>Task {{ index + 1 }} <i class="far fa-eye"></i></b>
                </a>
              </el-tooltip>
              <a
                v-else
                target="_blank"
                :href="getResolve(item.toefl_question_id)"
                class="view_question"
              >
                <b>Task {{ index + 1 }} <i class="far fa-eye"></i></b>
              </a>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <template v-if="item.user_answer && item.user_answer[0]">
                <div>
                  <div class="passage">
                    <div
                      class="text-left"
                      v-html="setText(item.user_answer[0])"
                    ></div>
                    <div class="text-right">
                      <b style="font-weight:500;">
                        <span class="font-color">{{
                          getWordCount(item.user_answer[0])
                        }}</span>
                        Words
                      </b>
                    </div>
                    <div v-if="examInfo.can_suggest === 1">
                      <div v-if="examInfo.suggest_type === 'outline'">
                        <el-divider content-position="left">
                          <b>{{ $t("GradingSamples.Simple Grading") }}</b>
                        </el-divider>
                      </div>
                      <div v-if="examInfo.suggest_type === 'detail'">
                        <el-divider content-position="left">
                          <b class="vip">
                            <el-tag type="warning" effect="plain">
                              <i class="fas fa-chess-queen"></i>
                              VIP
                            </el-tag></b
                          ><b>{{
                            $t("GradingSamples.VIP Grading")
                          }}</b></el-divider
                        >
                      </div>
                    </div>
                    <div v-else>
                      <el-divider content-position="left">
                        Grades and Comments
                      </el-divider>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div v-if="!item.user_exam_answer_metadata">
                          <div
                            @click="getAIAnswer(item)"
                            class="AI-button"
                            style="width: 100%;"
                          >
                            <b class="logo-color logo-title">AI</b>
                            Grading
                          </div>
                        </div>
                        <AI
                          v-if="item.user_exam_answer_metadata"
                          content="Grading"
                          @getAIAlertSpeaking="$emit('getAIAlert', item)"
                        >
                          <div
                            class="text-left d-flex align-items-center mb-2  mt-3"
                          >
                            <b class="score-title">Response Score:&nbsp;</b>
                            <div>
                              <b v-if="item.user_exam_answer_metadata">
                                <span class="score">
                                  {{
                                    `${item.user_exam_answer_metadata.ai_score} / 5`
                                  }}
                                </span>
                              </b>
                              <template v-else>
                                -
                              </template>
                            </div>
                          </div>
                          <div class="text-left">
                            <div class="overall-comments score-title">
                              <b>Overall Comments:</b>
                            </div>
                            <div v-if="item.user_exam_answer_metadata">
                              <div
                                v-html="
                                  item.user_exam_answer_metadata.ai_feedback
                                "
                              />
                            </div>
                            <template v-else>
                              -
                            </template>
                          </div>
                        </AI>
                      </div>
                      <div class="col-6">
                        <div class="human-card">
                          <div class="human-header">
                            Human Grading
                          </div>
                          <!-- <hr /> -->
                          <div class="human-content">
                            <div>
                              <div
                                class="col-sm-12 text-left d-flex align-items-center mt-3"
                              >
                                <b class="score-title">Response Score:&nbsp;</b>
                                <div v-if="examInfo.can_score || teacherScored">
                                  <b v-if="item.score > 0">
                                    <span class="score">
                                      {{ `${item.score}/5` }}
                                    </span>
                                  </b>
                                  <div v-else class="unlockTag">
                                    {{ $t("message.unlockScored") }}
                                  </div>
                                </div>
                                <!--  -->
                                <template v-else>
                                  <el-button
                                    type="warning"
                                    @click="$emit('openWarningSpeaking')"
                                  >
                                    <i class="fas fa-lock"></i>
                                    Human Grading
                                  </el-button>
                                </template>
                              </div>
                              <div class="col-sm-12">
                                <SubScoreTable
                                  v-if="
                                    commentCategories[
                                      `writing_${item.toefl_question_type}`
                                    ]
                                  "
                                  :value="
                                    (item.answer_score_detail || {})
                                      .detail_score_comments
                                  "
                                  :testCategory="
                                    `writing_${item.toefl_question_type}`
                                  "
                                  :testCategories="
                                    commentCategories[
                                      `writing_${item.toefl_question_type}`
                                    ]
                                  "
                                  :suggest_type="examInfo.suggest_type"
                                  :canScore="
                                    examInfo.can_score || teacherScored
                                  "
                                  :scored="examInfo.is_scored"
                                  @unlock="$emit('openWarningSpeaking')"
                                />
                              </div>
                            </div>
                            <div class="col-sm-12 text-left">
                              <div class="overall-comments score-title">
                                <b>Overall Comments:</b>
                              </div>
                              <div v-if="examInfo.can_suggest || teacherScored">
                                <div
                                  v-if="examInfo.is_scored"
                                  v-html="item.suggest"
                                />
                                <template v-else>
                                  <div
                                    v-if="examInfo.suggest_type === 'detail'"
                                    class="unlockTag"
                                  >
                                    {{ $t("message.unlockDetail") }}
                                  </div>
                                  <div
                                    v-else-if="
                                      examInfo.suggest_type === 'outline'
                                    "
                                    class="unlockTag"
                                  >
                                    {{ $t("message.unlockBasic") }}
                                  </div>
                                </template>
                              </div>
                              <!-- <el-tooltip
                                v-else
                                class="item"
                                effect="dark"
                                :content="
                                  $t('toefl.balance.Get Comments for This Prompt')
                                "
                                placement="top"
                              >
                                <el-button
                                  type="warning"
                                  size="small"
                                  @click="$emit('openWarningSpeaking')"
                                >
                                  <i class="fas fa-lock"></i>
                                </el-button>
                              </el-tooltip> -->
                              <div v-else>
                                <el-button
                                  type="warning"
                                  @click="$emit('openWarningSpeaking')"
                                >
                                  <i class="fas fa-lock"></i>
                                  Human Grading
                                </el-button>
                              </div>
                            </div>
                            <div
                              class="col-sm-12 text-left mt-3"
                              v-if="
                                examInfo.can_suggest === 1 &&
                                  examInfo.suggest_type === 'detail' &&
                                  (item.answer_score_detail || {})
                                    .overall_comment_url
                              "
                            >
                              <h6>
                                <a
                                  :href="
                                    item.answer_score_detail.overall_comment_url
                                  "
                                  ><i class="fas fa-file-alt"></i>
                                  <b>{{
                                    $t("GradingSamples.Google Doc")
                                  }}</b></a
                                >
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import SubScoreTable from "@/views/toefl/transcript/components/SubScoreTable";
import AI from "@/components/AI";
export default {
  components: {
    AI,
    SubScoreTable
  },
  props: [
    "list",
    "score",
    "examInfo",
    "isPDF",
    "commentCategories",
    "hideTitle"
  ],
  data() {
    return {
      answerDetail: {
        lockScore: true,
        score: 1,
        lockDetail: true,
        detail: ""
      }
    };
  },
  computed: {
    ...mapState("user", ["profile"]),
    isPro() {
      return this.$store.getters["user/getIsPro"];
    },
    teacherScored() {
      return (
        this.examInfo.can_score === 0 &&
        this.examInfo.can_suggest === 1 &&
        this.examInfo.is_scored === 1
      );
    }
  },
  watch: {},

  async mounted() {
    await this.getProfile();
  },

  methods: {
    ...mapActions("user", ["getProfile"]),
    getAIAnswer(item) {
      if (this.isPro) {
        this.$emit("getAIAlert", item);
      } else {
        this.$confirm(
          "Upgrade to <b>Pro</b> plan for unlimited access to AI Grading.",
          "Tips",
          {
            confirmButtonText: "View Pro plan",
            cancelButtonText: "Cancel",
            type: "warning",
            dangerouslyUseHTMLString: true
          }
        ).then(() => {
          this.$router.push({
            name: "ToeflPlans"
          });
        });
      }
    },
    getWordCount(string) {
      string = string.replace(/\t/g, " ");
      let word = 0;
      let count = 0;
      if (string == "" || string == undefined || string == null) {
        count = 0;
      } else {
        for (var i = 0; i < string.length; i++) {
          if (string[i] == " ") {
            word = 0;
          } else if (word == 0) {
            word = 1;
            count++;
          }
        }
      }
      return count;
    },
    getResolve(question_id) {
      return this.$parent.getResolve(question_id);
    },
    saveScore(user_exam_answer_id, score) {
      this.$parent.saveScore(user_exam_answer_id, score);
    },
    setText(text) {
      return text.replace(/\n/g, "<br />");
    },
    showTime(seconds) {
      let minute;
      let second;
      if (seconds && seconds > 0) {
        minute = Math.floor(seconds / 60);
        second = Math.floor(seconds) - minute * 60;
      } else {
        minute = 0;
        second = 0;
      }
      // 让个位数的时间正常显示
      if (minute <= 9) minute = "0" + minute;
      if (second <= 9) second = "0" + second;
      return `花費時間： ${minute}:${second}`;
    }
  }
};
</script>

<style scoped>
.green-td {
  position: relative;
}

.view_question {
  color: #fff !important;
  text-decoration: none;
}

.view-question-tr:hover {
  opacity: 0.7;
}

.view_question:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
td a {
  cursor: pointer;
}
hr {
  margin-bottom: 0;
}
.vip {
  color: #f0ad4e;
}
.vip >>> .el-tag {
  margin-right: 8px;
  height: 20px;
  line-height: 18px;
  padding: 0 4px;
}
::v-deep .table-pdf {
  line-height: 20px;
}
::v-deep .table-pdf p {
  margin-bottom: 5px;
}
@media screen and (max-width: 768px) {
  thead {
    display: none;
  }
  tbody {
    width: 100%;
  }
  th,
  td {
    /* height: 45px; */
    padding: 10px;
    line-height: 45px;
    display: block;
  }
  th {
    width: 150px;
  }
  .table th {
    min-width: 75px;
    max-width: 85px;
  }
  td a {
    cursor: pointer;
  }
}
.tip {
  padding: 8px 16px;
  background-color: var(--themeColor) 40;
  border-radius: 4px;
  border-left: 5px solid var(--themeColor);
  margin: 0 0 20px 0;
}
.tip .text {
  font-size: 14px;
  font-weight: 500;
}

.unlockTag {
  background-color: #ecf5ff;
  display: inline-block;
  height: 32px;
  padding: 0 10px;
  line-height: 30px;
  font-size: 12px;
  color: #409eff;
  border: 1px solid #d9ecff;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
}
.overall-comments {
  margin-top: 4px;
  margin-bottom: 0.5rem;
}
.human-card {
  border-radius: 1rem;
  box-shadow: 0 2px 0.5rem 0 rgba(0, 0, 0, 0.08);
}
.human-header {
  text-align: left;
  font-size: 1.1rem;
  border-radius: 1rem 1rem 0 0;
  font-weight: 700;
  padding: 1rem;
  background: linear-gradient(hsla(0, 0%, 100%, 0), #fff) padding-box,
    linear-gradient(180deg, #f0ad4e, #f4ffa0, #fff) border-box;
}
.human-content {
  padding: 0 0 1rem 0;
}
</style>
